

























































































































import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import {
    apiDeliverymanAdd,
    apiDeliverymanDetail,
    apiDeliverymanEdit,
    apiCommunityList
} from '@/api/delivery'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddCategory extends Vue {
    $refs!: { form: any }
    id!: any
    loading = false
    hasPid = 0
    communityList = []
    lock = false
    form: any = {
        disable: 1
    }

    rules = {
        name: [
            {
                required: true,
                message: '请输入配送员名称',
                trigger: ['blur', 'change']
            }
        ],
        mobile: [
            {
                required: true,
                message: '请输入手机号',
                trigger: ['blur', 'change']
            },
            {
                pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                message: '请输入正确的手机号',
                trigger: ['blur', 'change']
            }
        ],
 
    }

    handleSave() {
        console.log(this.form)
        if(this.lock) return
        this.lock = true;
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                let form: any = {}
                this.loading = true
                form = { ...this.form, deliveryman_id: this.id }

                const api = this.id
                    ? apiDeliverymanEdit(form)
                    : apiDeliverymanAdd(form)
                api.then(() => {
                    this.lock = false;
                    this.$router.go(-1)
                    this.loading = false
                }).catch(() => {
                    this.lock = false;
                    this.loading = false
                })
            } else {
                this.lock = false;
                return false
            }
        })
    }
    // 小区详情
    getCategoryDetail() {
        this.loading = true
        apiDeliverymanDetail(this.id).then((res: any) => {
            if (res.pid) {
                this.hasPid = 1
            }
            this.form = res
            this.loading = false
            console.log('form', this.form)
        })
    }
    // 获取小区列表
    getcommunityList() {
        apiCommunityList({ page_size: 10000, status: 1 }).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
            this.communityList = res?.lists
            this.id && this.getCategoryDetail()
        })
    }

    created() {
        this.id = this.$route.query.id

        this.getcommunityList()
    }
}
